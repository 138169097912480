<template>
    <div class="wrapper_main" style="min-height: 100dvh;">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('dispatch') }}</h1>
                </div>
                <div class="content__header-right">
                    <router-link type="button" class="btn btn-primary btn-standart" to="/create-dispatch">
                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                        <span>{{ $translate('create-a-dispatch') }}</span>
                    </router-link>
                </div>
            </div>
            <TableAdvanced
                v-model="sortCol"
                :rows="filteredRows"
                :columns="columns"
                :isCheckedAll="isCheckedAll"
                :checkedInputs="checkedInputs"
                @toggleAllCheckedInputs="toggleAllCheckedInputs"
                :sortFieldsForDropdown="sortFieldsForDropdown"
                filterWrapperStyle="flex-wrap: wrap; gap: 10px;"
            >
                <template v-slot:filters>
                    <form action="#" class="filter-options__search filter-options__search--md" style="min-width: 250px;">
                        <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
                    </form>
                    <!-- <div class="filter-options__items flex-x-wrap flex-auto"> -->
                        <DropdownAdvanced :options="statusOptions" v-model="rowsFilters.localStatus" placeholder="Select Status" style="max-width: 250px; margin-right: 0px;" />
                        <DropdownAdvanced
                            :options="[
                                {id: 'all', name: 'All receivers'}, 
                                ...uniqueReceivers
                            ]"
                            v-model="rowsFilters.receiver" placeholder="Receiver" style="max-width: 250px; margin-right: 0px;"
                        />
                        <!-- checkSmartConditions(); -->
                        <DropdownAdvanced 
                            :options="[{id: 'all', name: 'All Statuses'}, {id: 'true', name: 'True'}, {id: 'false', name: 'False'}]"
                            :current="rowsFilters.conditionStatusSmart"
                            @input="(value)=>{
                                selectConditionFilter('conditionStatusSmart', value)
                            }"
                            placeholder="Smart condition status" style="max-width: 250px; margin-right: 0px;"
                        />
                        <!-- conditionStatusBusiness -->
                        <DropdownAdvanced
                            :options="[{id: 'all', name: 'All Statuses'}, {id: 'true', name: 'True'}, {id: 'false', name: 'False'}]"
                            :current="rowsFilters.conditionStatusBusiness"
                            @input="(value)=>{
                                selectConditionFilter('conditionStatusBusiness', value)
                            }"
                            placeholder="Bus condition status" style="max-width: 250px; margin-right: 0px;" 
                        />
                        <div>
                            <!-- <label class="el-form__title select-title" style="display: block;">Date creation</label> -->
                            <DatePicker
                                placeholder="Date creation"
                                dateFormat="MM.dd.yyyy" :canClear="true" :value="rowsFilters.createdAtDate"
                                @input="(value)=>{if(value){ $set(rowsFilters, 'createdAtDate', new Date(value).toLocaleDateString().split('.').join(''))} else { $set(rowsFilters, 'createdAtDate', 'all') }}"
                            />
                        </div>
                        <!-- Array.from() -->
                        <DropdownAdvanced
                            :options="[ {id: 'all', name: 'All owners'}, ...[...new Set(dispatches.map(item => item.ownerFullName))].map(item => ({id: item, name: item})) ]"
                            v-model="rowsFilters.ownerFullName" placeholder="Owner" style="max-width: 250px; margin-right: 0px;"
                        />
                        {{rowsFilters.ownerFullName}}
                    <!-- </div> -->
                </template>
                <template v-slot:row="slotData">
                    <td>{{ slotData.r.createdAt | formatDate }}</td>
                    <td>
                        <label class="module__check">
                            <!-- <input type="checkbox" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event, slotData.r.id)" />
                            <span class="check"></span> -->
                            <router-link :to="`/dispatch/${slotData.r.id}`" class="text" style="text-decoration: underline;">
                                {{ slotData.r.dispatchID }}
                            </router-link>
                        </label>
                    </td>
                    <td>
                        <span class="table-statys enable">
                            <span class="dot"></span>
                            {{ slotData.r.localStatus }}
                        </span>
                    </td>
                    <td>
                        <span class="table-user flex items-center">
                            <img v-if="userAvatars.find(itemF => itemF.id === slotData.r.ownerID)" :src="userAvatars.find(itemF => itemF.id === slotData.r.ownerID).url"  />
                            <img v-else src="@/assets/img/user.png" >
                            <span class="user-fullname">{{ slotData.r.ownerFullname }}</span>
                        </span>
                    </td>
                    <td>
                        {{ companiesSource.length ? companiesSource.find(cItem => cItem.id === slotData.r.companies.items[0].companyID).company_name : '-' }}
                    </td>
                    <td>{{ slotData.r.batchesCount }}</td>
                    <td>{{ slotData.r.productsCount }}</td>
                    <td>
                        {{ returnCondition(slotData.r, "smart") ? operators[returnCondition(slotData.r, "smart").operator] : '' }}
                        {{ showItemConditionStatus(slotData.r, "smart") }}
                        <template v-if="slotData.r.contract_conditions.filter(itemCond => itemCond.condition_type === 'smart').length">
                            <span class="condition_status" :class="[smartConditionsStatus.includes(slotData.r.id) ? 'green' : 'red']" v-if="!loadingSmartConditionStatus.includes(slotData.r.id)"></span>
                            <span v-else class="condition_status processing"></span>
                        </template>
                    </td>
                    <td style="padding-right: 50px">
                        {{ showItemConditionStatus(slotData.r, "business") }}
                        <span class="condition_status" v-if="slotData.r.contract_conditions.filter(itemCond => itemCond.condition_type === 'business').length" :class="checkBusinessCondition(slotData.r) ? 'green' : 'red'"></span>
                    </td>
                    <td class="position-stiky">
                        <div class="table-options-drop dropdown-wrapper">
                            <button type="button" class="btn btn-sm btn-transp dropdown-btn" :class="{ active: menuOpen.open === slotData.r.id }" @click="openOptions($event, slotData.r.id)">
                                <inline-svg :src="require('@/assets/img/dots-menu.svg')" class="pointer-events-none" />
                            </button>
                            <div
                                class="categories__dropdown dropdown-content"
                                :class="{ active: menuOpen.open === slotData.r.id }"
                                style="position: fixed"
                                :style="{ left: menuOpen.left, top: menuOpen.top, bottom: 'auto' }"
                            >
                                <ul>
                                    <li>
                                        <a @click="() => { menuOpen.open = false; $router.push({ path:`/dispatch/${slotData.r.id}` }); }">
                                            {{ $translate('view-the-dispatch') }}
                                        </a>
                                    </li>
                                    <li v-if="slotData.r.localStatus == 'incoming'">
                                        <a @click="() => { menuOpen.open = false; receiveDispatch(slotData.r.id, companyData.id); }">
                                            {{ $translate('receive-the-dispatch') }}
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <router-link :to="`/track-dispatch/${slotData.r.id}`">Track the Dispatch</router-link>
                                    </li> -->
                                    <li v-if="slotData.r.localStatus == 'incoming'">
                                        <a @click="() => { menuOpen.open = false; returnDispatch(slotData.r.id, companyData.id); }">
                                            {{ $translate('return-the-dispatch') }}
                                        </a>
                                    </li>
                                    <li class="list-line"></li>
                                    <li>
                                        <a class="btn red-color" @click="() => { menuOpen.open = false; confirmDelete(slotData.r.id, 'Delete Dispatch?'); }">
                                            {{ $translate('delete-the-dispatch') }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </template>
            </TableAdvanced>
            <TableMessages
                :rows="rows"
                :filters="rowsFilters"
                :title="$translate('no-dispatches-yet!')"
                :searchTitle="$translate('no-dispatches-corresponding-to-the-criteria')"
            ></TableMessages>
            <!-- 
                before translate
                title="No Dispatches yet!"
                searchTitle="No Dispatches Corresponding To The Criteria"
             -->
        </main>
        <Pagination v-model="filteredRows" :items="rawSource" />

    </div>
</template>

<script>
import { getProduct, getDevice, listCompanies, } from "@/graphql/queries";
import { updateProduct } from "@/graphql/mutations";
import { API, Storage } from "aws-amplify";
import { mixTables, mixDeleteItem } from "@/mixins";
import { mixDispatches } from "@/mixins/dispatch";
import { statuses, operators } from "@/config/dispatch";
import { devicesDataCategories } from '@/config';
import Base from "@/components/base";

export default {
    name: "Dispatch",
    mixins: [mixTables, mixDeleteItem, mixDispatches],
    components: {
        ...Base,
    },
    data() {
        return {
            source: "dispatches",

            columns: [
                { name: "Date of creation", filter: "createdAt", disableCheckbox: true },
                { name: "Dispatch ID", filter: "dispatchID" },
                { name: "Status", filter: "status" },
                { name: "Owner", filter: "ownerFullname" },
                { name: "Receiver" },
                { name: "No. Batch", filter: "batchesCount" },
                { name: "No. Products", filter: "productsCount" },
                { name: "Smart condition status", filter: "" },
                { name: "Business condition status", filter: "" },
            ],
            calculatedFields: [
                {
                    filter: "batchesCount",
                    path: ["batches"],
                    calcFunction: (bathces) => {
                        return bathces.length;
                    },
                },
                {
                    filter: "productsCount",
                    path: ["batches"],
                    calcFunction: (batches) => {
                        return (batches.map(b=>b.products).flat() || []).length
                    }
                },
                {
                    filter: "ownerFullname",
                    path: ["owner"],
                    calcFunction: (owner) => {
                        return owner.fullname || "none";
                    },
                },
                {
                    filter: "localStatus",
                    path: [],
                    calcFunction: (dispatch) => {
                        return  (this.companyData.id === dispatch.owner.company.id ? this.getGlobalDispatchStatus(dispatch) : dispatch.companiesStatus.find((obj) => obj.id == this.companyData.id)?.status) || "null";
                    },
                },
            ],
            searchField: "dispatchID",
            deleteQueryType: "deleteDispatch",
            menuOpen: {
                left: "-500px",
                top: 0,
                open: null,
            },
            userAvatars: [],
            categories: devicesDataCategories,
            smartConditionsStatus: [],
            operators: operators,
            loadingSmartConditionStatus: [],
            companiesSource: [],
            smartConditionChange: false,
            businessConditionChange: false,
            smartConditions: [],
            businessConditions: [],
        };
    },
    computed: {
        companyData() {
            return this.$store.state.companyData || {};
        },
        isSort() {
            return (field) => (field === this.sortField ? "active" : "");
        },
        dispatches() {
            return this.$store.state.dispatches || [];
        },
        uniqueReceivers() {
            return Array.from(
                new Set(
                    this.dispatches.map(item => item.receiver)
                )
            ).map(item => ({id: item, name: item}));
        },
        sourceData() {
            return JSON.parse(JSON.stringify(this.$store.state.dispatches)).map(item => ({
                ...item,
                conditionStatusBusiness: this.businessConditions.find(itemF => itemF.id === item.id)?.conditionStatusBusiness || 'false',
                conditionStatusSmart: this.smartConditions.find(itemF => itemF.id === item.id)?.conditionStatusSmart || 'false'
            })) || [];
        },
        sortFieldsForDropdown() {
            return [
                { name: "Date creation", filter: "createdAt" },
                { name: "Update At", filter: "updatedAt" },
                { name: "Name", filter: "dispatchID" },
            ];
        },
        statusOptions() {
            return [
                { name: "All statuses", id: "all" },
                ...statuses.map((s) => {
                    return {
                        name: s,
                        id: s.toLowerCase(),
                    };
                }),
            ];
        },
    },
    watch: {
        dispatches: {
            handler(newValue, oldValue) {
                console.log('dispatches change:', newValue);
                setTimeout(() => {
                   this.checkSmartConditions(); 
                }, 500);
            },
            deep: true
        }
    },
    methods: {
        async selectConditionFilter(field, value){
            // console.log('selectConditionFilter:', field, value);
            // if(field === 'conditionStatusSmart'){
            //     this.smartConditionChange = false;
            //     this.rowsFilters.conditionStatusSmart = value;

            //     setTimeout(async ()=>{
            //         await this.checkSmartConditions();
            //     }, 1000);
            // } else {
            //     // this.rowsFilters.conditionStatusBusiness = value;
            //     this.$set(this.rowsFilters, 'conditionStatusBusiness', String(value));
            // }
            this.$set(this.rowsFilters, field, String(value));
        },
        // toDispatch(dispatch){
        //     let companyStatus = dispatch.companiesStatus.find(c=>{
        //         return c.id==this.companyData.id
        //     })
        //     if(companyStatus.type=='outcoming'){
        //         this.$router.push(`/dispatch/${dispatch.id}`)
        //     }
        //     if(companyStatus.type=='incoming'){
        //         if(companyStatus.status=='pending'){
        //             this.$router.push(`/dispatch/approval/${dispatch.id}`)
        //         }
        //     }
        // },
        showItemConditionStatus(item, type) {
            let findedconditions = item.contract_conditions.find((conditionItem) => conditionItem.condition_type === type);
            
            if (findedconditions && type === 'business') {
                return ''
                // this.formatBusinessCondition(findedconditions);
            }

            if (findedconditions) {
                return findedconditions.value ? findedconditions.value : "n/a";
            } else {
                return "n/a";
            }
        },
        formatBusinessCondition(condition) {
            const checkInvalidDateTime = (value) => [].includes(value) ? null : value;
            const formatDateRange = ([dateFromStr, timeFromStr], [dateToStr, timeToStr]) => {
                let igoreYear = dateFromStr.split('.').at(-1) === dateToStr.split('.').at(-1);

                let dateFrom = dateFromStr, dateTo = dateToStr;

                if (igoreYear) {
                    dateFrom = dateFrom.substr(0, 5);
                    dateTo = dateTo.substr(0, 5);
                }

                return `${dateFrom}${timeFromStr ? ','+timeFromStr : ''}-${dateTo}${timeToStr ? ','+timeToStr : ''}`;
            };

            if (condition.from_date && condition.to_date) {
                return formatDateRange([condition.from_date, condition.from_time], [condition.to_date, condition.to_time])
            }
        },
        returnCondition(item, type){
            return item.contract_conditions.find((conditionItem) => conditionItem.condition_type === type);
        },
        updateFilterData(ev) {
            const target = ev.target;
            const value = target.value;
            const name = target.name;
            this[name] = value;
        },
        openOptions(ev, id) {
            if (this.menuOpen.open === id) {
                this.menuOpen.open = null;
                this.menuOpen = {
                    left: "-500px",
                    top: 0,
                    open: null,
                };
            } else {
                this.menuOpen.open = id;
                setTimeout(() => {
                    let targetClientRect = ev.target.getBoundingClientRect();

                    this.menuOpen.left = targetClientRect.x - ev.target.nextElementSibling.offsetWidth + 22 + "px";

                    if (targetClientRect.top + targetClientRect.height + ev.target.nextElementSibling.offsetHeight + 70 > document.querySelector("#app").offsetHeight) {
                        this.menuOpen.top = targetClientRect.y - ev.target.nextElementSibling.offsetHeight - 10 + "px";
                    } else {
                        this.menuOpen.top = targetClientRect.y + 15 + "px";
                    }
                }, 10);
            }
        },
        outsideMenuClick(e) {
            if (this.menuOpen.open && !e.target.closest(".table-options-drop.dropdown-wrapper")) {
                this.menuOpen.open = null;
            }
        },
        async additionalDeleteActions(id) {
            let dispatch = this.dispatches.find((o) => {
                return o.id == id;
            });
            if (dispatch) {
                let requests = dispatch.companies.items.map(async (o) => {
                    await this.deleteQuery("deleteCompanyDispatch", o.id);
                });
                await Promise.all(requests);
            }
            for (let b of dispatch.batches) {
                for (let p of b.products) {
                    await API.graphql({
                        query: updateProduct,
                        variables: {
                            input: {
                                id: p.id,
                                dispatches: null,
                            },
                        },
                    });
                }
            }
        },
        // async receiveDispatch(dispatchID) {
        //     const options = { title: "Receive Dispatch?", size: "sm", okLabel: "Receive", cancelLabel: "Cancel" };
        //     await this.$dialogs.confirm("", options).then(async (res) => {
        //         if (res.ok) {
        //             await this.changeCompanyStatus(dispatchID, this.companyData.id, "completed");
        //             await this.changeCompanyStatus(dispatchID, this.companyData.id, "receive");
        //         }
        //     });
        // },
        // async returnDispatch(dispatchID) {
        //     const options = { title: "Return Dispatch?", size: "sm", okLabel: "Return", cancelLabel: "Cancel" };
        //     await this.$dialogs.confirm("", options).then(async (res) => {
        //         if (res.ok) {
        //             await this.changeCompanyStatus(dispatchID, this.companyData.id, "returned");
        //         }
        //     });
        // },
        returnStatus(item, type) {
            return item.companiesStatus.find(el => el.status == type);
        },
        checkBusinessCondition(data) {
            const condition = this.returnCondition(data, 'business');

            // let copiedDispatch = JSON.parse(JSON.stringify(this.dispatches));

            const setBusinessConditionValue = (value) => {
                if(!this.businessConditionChange){
                    if(this.businessConditions.find(item => item.id === data.id)){
                        this.businessConditions.find(item => item.id === data.id).conditionStatusBusiness = String(value);
                    } else {
                        this.businessConditions.push({
                            id: data.id,
                            conditionStatusBusiness: String(value)
                        })
                    }

                    this.businessConditionChange = true;
                }
            }

            if (!condition) return false;

            const d = new Date();
            const getDate = (dateString, timeString) => {
                let [year, month, day] = dateString.split('.').reverse(),
                    [hour, minute] = timeString.split(':');

                return new Date(year, (month - 1), day, hour, minute)
            };

            let completedStatus = this.returnStatus(data, 'completed');

            if (condition.type == 'delivery_time' && condition.date_from && condition.date_to) {
                if (!completedStatus) return false;

                let deliveryDate = new Date(parseInt(completedStatus.delivered_date)).getTime(),
                    dateFrom = getDate(condition.date_from || d.toLocaleDateString(), condition.time_from || ':').getTime(),
                    dateTo = getDate(condition.date_to || d.toLocaleDateString(), condition.time_to || ':').getTime();

                setBusinessConditionValue(deliveryDate >= dateFrom && deliveryDate <= dateTo);

                return deliveryDate >= dateFrom && deliveryDate <= dateTo;
            }

            if (condition.type == 'delivery_time' && condition.date) {
                if (!completedStatus) return false;

                let deliveryDate = new Date(parseInt(completedStatus.delivered_date)).getTime(),
                    conditionDate = getDate(condition.date || d.toLocaleDateString(), condition.time_from || ':').getTime();

                setBusinessConditionValue(deliveryDate <= conditionDate);

                return deliveryDate <= conditionDate;
            }

            if (condition.type == 'certifications' && condition.event_to_be_monitored == "presence") {
                setBusinessConditionValue(data.status !== 'canceled');
                
                return data.status !== 'canceled';
            }

            return false;
        },
        async checkSmartConditions(){
            this.smartConditionsStatus = [];

            let copiedDispatch = JSON.parse(JSON.stringify(this.dispatches));

            this.dispatches.forEach(async (dispatch, dispatchIndex) => {
                let findSmartCondition = dispatch.contract_conditions.find(item => item.condition_type === "smart");
                if(findSmartCondition){
                    this.loadingSmartConditionStatus.push(dispatch.id);

                    let splitMinDate = findSmartCondition.from_date.split('.');
                    let splitMaxDate = findSmartCondition.to_date.split('.');

                    let timeDiff = Math.abs(
                        new Date(`${ splitMinDate[2]+'-'+splitMinDate[1]+'-'+splitMinDate[0] }T${findSmartCondition.from_time && findSmartCondition.from_time ? findSmartCondition.from_time : '00:00'}:00.000Z`).getTime() - 
                        new Date(`${ splitMaxDate[2]+'-'+splitMaxDate[1]+'-'+splitMaxDate[0] }T${findSmartCondition.to_time ? findSmartCondition.to_time : '00:00'}:00.000Z`).getTime()
                    );
                    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                    if(new Date(`${ splitMaxDate[2]+'-'+splitMaxDate[1]+'-'+splitMaxDate[0] }T${findSmartCondition.to_time ? findSmartCondition.to_time : '00:00'}:00.000Z`) < new Date()){
                        this.loadingSmartConditionStatus.splice(this.loadingSmartConditionStatus.findIndex(item2 => item2 === dispatch.id), 1)
                        return false;
                    }

                    const device = await API.graphql({
                        query: getDevice,
                        variables: {
                            id: findSmartCondition.select_device,
                        },
                    }).then((res) => res.data.getDevice);

                    if(device && device.config && device.config.device_id && this.categories[device.category]) {

                        let lastValueData = {};

                        if(device.category === 'carbon-dioxide' || device.category === 'phosphine'){
                            let realDeviceData = await this.$provider.centaurAPI.getRealDeviceData(device.config.device_id);

                            if(realDeviceData && realDeviceData.length) {
                                let filteredByCategoryData = realDeviceData.filter(item => item.type === this.categories[device.category].category);
                                lastValueData = filteredByCategoryData[filteredByCategoryData.length-1];
                            }
                        } else {

                            let dataFromxFarm = null;
                            try { 
                                dataFromxFarm = await this.$provider.xFarm.getDeviceData(
                                device.config.device_id,
                                {
                                    from: new Date(`${ splitMaxDate[2]+'-'+splitMaxDate[1]+'-'+splitMaxDate[0] }T${findSmartCondition.to_time && !findSmartCondition.to_time.includes('HH') ? findSmartCondition.to_time : '00:00'}:00.000Z`).toISOString(),
                                    periodTo: `${ diffDays }d`
                                }
                            )} catch (e) {
                                dataFromxFarm = [];
                                this.loadingSmartConditionStatus.splice(this.loadingSmartConditionStatus.findIndex(item2 => item2 === dispatch.id), 1);
                            }

                            let valueVariableName = null;

                            if(findSmartCondition.parameter_obeserved === 'temperature' || findSmartCondition.parameter_obeserved === 'temperature-humidity' || findSmartCondition.parameter_obeserved === 'weather-station-xsense') valueVariableName = 'at';
                            if(findSmartCondition.parameter_obeserved === 'leaf-moisture') valueVariableName = 'lw';
                            if(findSmartCondition.parameter_obeserved === 'soil-moisture') valueVariableName = 'sm1';
                            if(findSmartCondition.parameter_obeserved === 'dew-point-temp') valueVariableName = 'atd';

                            let realDeviceData = dataFromxFarm.map(item => {
                                return {
                                    "timestamp": item.time,
                                    "type": this.categories[findSmartCondition.parameter_obeserved].category,
                                    "value": item[valueVariableName],
                                    "deviceId": item.did,
                                    "fullData": item
                                }
                            });

                            if(realDeviceData && realDeviceData.length) {
                                lastValueData = realDeviceData[realDeviceData.length-1];
                            }
                        }

                        if(lastValueData.value){
                            let statusCondition = false;
                            
                            switch (findSmartCondition.operator) {
                                case 'equal':
                                    if(+lastValueData.value === +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'not_equal':
                                    if(+lastValueData.value !== +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'greater_than':
                                    if(+lastValueData.value > +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'less_than':
                                    if(+lastValueData.value < +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'equal_or_grater_than':
                                    if((+lastValueData.value === +findSmartCondition.value) || (+lastValueData.value > +findSmartCondition.value)) statusCondition = true;
                                    break;
                                case 'equal_or_less_then':
                                    if((+lastValueData.value === +findSmartCondition.value) || (+lastValueData.value < +findSmartCondition.value)) statusCondition = true;
                                    break;
                                default:
                                    break;
                            }

                            if(statusCondition){
                                this.smartConditionsStatus.push(dispatch.id);
                                // dispatch
                                // conditionStatusBusiness
                            } else {
                                dispatch.conditionStatusSmart = false;
                            }

                            copiedDispatch[dispatchIndex].conditionStatusSmart = String(statusCondition);
                        }
                    }

                    this.loadingSmartConditionStatus.splice(this.loadingSmartConditionStatus.findIndex(item2 => item2 === dispatch.id), 1)
                } else {
                    copiedDispatch[dispatchIndex].conditionStatusSmart = 'false';
                }
            });


            setTimeout(() => {
                if(!this.smartConditionChange){
                    copiedDispatch.forEach(data => {
                        if(this.smartConditions.find(item => item.id === data.id)){
                            this.smartConditions.find(item => item.id === data.id).conditionStatusSmart = String(data.conditionStatusSmart);
                        } else {
                            this.smartConditions.push({
                                id: data.id,
                                conditionStatusSmart: String(data.conditionStatusSmart)
                            })
                        }
                    });

                    // this.$store.commit('SET_DISPATCHES', copiedDispatch);
                    this.smartConditionChange = true;
                }
            }, 2000);
        },
    },
    async created() {
        let self = this;
        window.addEventListener("click", self.outsideMenuClick);

        const companies = await API.graphql({
            query: listCompanies,
            variables: {}
        }).then(res => res.data.listCompanies.items);

        this.companiesSource = companies;
    },
    async mounted(){
        let self = this;
        let countAvatarGetAction = 0;
        let getUsersAvatar = function(){
            setTimeout(async () => {
                let requests = self.dispatches.map(async item => { 
                    return {
                        id: item.owner.id,
                        url: await Storage.get(item.owner.profile_photo)
                    }
                });
                await Promise.all(requests).then(responses => self.userAvatars = responses);
            
                if((!self.userAvatars || !self.userAvatars.length) && countAvatarGetAction < 5){
                    getUsersAvatar();
                }
            }, 1000);
            countAvatarGetAction++;
        }

        getUsersAvatar();
    },
    beforeDestroy() {
        let self = this;
        window.removeEventListener("click", self.outsideMenuClick);
    },
};
</script>

<style scoped>
.table-user {
    padding-right: 30px;
}
.module__check .text {
    margin-left: 0;
}
</style>
